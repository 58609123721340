<template>
  <div class="audit-records">
    <div class="_BoxHeader">
      <span>7S审核分数汇总</span>
    </div>
    <div class="PageContain">
      <div class="_SelectHeader" style="padding-bottom: 0px">
        <div class="_Select">
          <span>公司：</span>
          <el-select placeholder="请选择公司" class="select" v-model="company">
            <el-option
              v-for="item in options"
              :key="item.value"
              :value="item.id"
              :label="item.companyName"
            >
              {{ item.companyName }}
            </el-option>
          </el-select>
        </div>
        <div class="block" style="margin-left: 20px">
          <span class="demonstration">时间：</span>
          <el-date-picker
            style="width: 130px"
            v-model="time1"
            type="date"
            placeholder="开始时间"
          >
          </el-date-picker>
          <span style="margin: 0 5px">至</span>
          <el-date-picker
            style="width: 130px"
            v-model="time2"
            type="date"
            placeholder="结束时间"
          >
          </el-date-picker>
        </div>

        <el-button icon="el-icon-search" @click="searchData"></el-button>
        <el-button class="_ImgBtn" @click="showExportExcel"
          ><img src="../../assets/images/img1/import2.png" />导出</el-button
        >
        <el-button v-if="!showFaterPage" @click="goBack" icon="el-icon-back"
          >返回</el-button
        >
      </div>
      <div style="margin: 10px">
        <el-table
          :data="tableData.slice(start, end)"
          :height="elementHeight"
          style="width: 99%"
          border
          id="table"
          @row-dblclick="rowDblclick"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
        >
          <el-table-column prop="companyName" label="公司名称">
          </el-table-column>
          <el-table-column
            prop="areaName"
            :label="showFaterPage ? '区域个数' : '抽查区域'"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            :label="showFaterPage ? '地点个数' : '地点'"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="titleCount" label="抽题总数" width="130">
          </el-table-column>
          <el-table-column prop="notCount" label="不适用" width="130">
          </el-table-column>
          <el-table-column prop="score" label="得分" width="130">
          </el-table-column>
          <el-table-column prop="failCount" label="问题点" width="130">
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="curSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
          class="_Pagination"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="导出" top="2vh" :visible.sync="dialogVisible" width="80%">
      <el-table
        :data="tableData"
        :height="elementHeight"
        style="width: 100%"
        border
        id="excelTable"
        :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      >
        <el-table-column prop="companyName" label="公司名称"> </el-table-column>
        <el-table-column prop="areaName" label="抽查区域" width="200">
        </el-table-column>
        <el-table-column prop="remark" label="地点" width="200">
        </el-table-column>
        <el-table-column prop="titleCount" label="抽题总数" width="130">
        </el-table-column>
        <el-table-column prop="notCount" label="不适用" width="130">
        </el-table-column>
        <el-table-column prop="score" label="得分" width="130">
        </el-table-column>
        <el-table-column prop="failCount" label="问题点" width="130">
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="reqExportExcel">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get } from '../../api/http'
import { exportExcel } from '../../modules/ExportExcel'
export default {
  data() {
    return {
      tableData: [],
      elementHeight: 0,
      options: [],
      company: '',
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: 1,
      time1: '',
      time2: '',
      dialogVisible: false,
      showFaterPage: true,
    }
  },
  mounted() {
    this.getElementHeight()
    this.options = this.$store.state.companyUserList
    this.getTableData()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 170)
      })
    },
    getTableData() {
      get(
        (this.showFaterPage
          ? '/api/AreaAudit/Get7sAuditMsgCensus?'
          : '/api/AreaAudit/Get7sAuditMsg?CompId=' + this.company + '&') +
          'DateFrom=' +
          (this.time1 ? new Date(this.time1).toLocaleString() : '') +
          '&DateTo=' +
          (this.time2 ? new Date(this.time2).toLocaleString() : '')
      ).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data
        }
      })
    },
    searchData() {
      this.showFaterPage = this.company ? false : true
      this.handleCurrentChange(1)
      this.getTableData()
    },
    goBack() {
      this.showFaterPage = true
      this.company = ''
      this.getTableData()
    },
    rowDblclick(e) {
      this.showFaterPage = false
      this.company = e.companyId
      this.getTableData()
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    showExportExcel() {
      this.dialogVisible = true
    },
    reqExportExcel() {
      this.dialogVisible = false
      return exportExcel('#excelTable', '7S审核分数汇总.xlsx')
    },
  },
}
</script>

<style lang="less" scoped>
</style>